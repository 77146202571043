var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard__container"},[_c('div',[_c('div',{staticClass:"chart__title"},[_c('h4',[_vm._v("Mercados x percentil?")]),_c('Menu',{attrs:{"size":"medium","direction":"left","closeOnItemClick":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('filter-item',{attrs:{"filter":{
              id: 'positionsId',
              name: 'Posiciones',
              options: _vm.positions,
            }}})]},proxy:true},{key:"options",fn:function(){return _vm._l((_vm.positions),function(position){return _c('menu-item',{key:position.id,on:{"click":function($event){_vm.marketSelectedPosition = position.id;
              _vm.positions.map(function (pos) { return (pos.active = false); });
              position.active = true;}}},[_vm._v(" "+_vm._s(position.name)+" "),(_vm.marketSelectedPosition === position.id)?_c('unicon',{attrs:{"width":"16px","height":"16px","name":"check","fill":""}}):_vm._e()],1)})},proxy:true}])})],1),_c('bar-chart',{attrs:{"chart-data":_vm.marketsData,"chart-options":{
        indexAxis: 'x',
        max: 1,
        steps: 5,
        stepSize: 0.2,
        plugins: {
          tooltip: {
            callbacks: {
              label: function (ctx) {
                return [("Valor: " + (Math.round(ctx.parsed.y * _vm.selectedPositionEmployees.length)))];
              },
              title: function (ref) {
                    var context = ref[0];

                    return ("" + (_vm.marketsData.datasets[context.datasetIndex].label));
},
            },
          },
        },
      }}})],1),_c('div',[_c('div',{staticClass:"chart__title"},[_c('h4',[_vm._v("Años en la empresa x percentil?")]),_c('Menu',{attrs:{"size":"medium","direction":"left","closeOnItemClick":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('filter-item',{attrs:{"filter":{
              id: 'marketsId',
              name: 'Mercados',
              options: _vm.markets,
            }}})]},proxy:true},{key:"options",fn:function(){return _vm._l((_vm.markets),function(market){return _c('menu-item',{key:market.id,on:{"click":function($event){_vm.selectedMarket = market.id;
              _vm.markets.map(function (mark) { return (mark.active = false); });
              market.active = true;}}},[_vm._v(" "+_vm._s(market.name)+" "),(_vm.selectedMarket === market.id)?_c('unicon',{attrs:{"width":"16px","height":"16px","name":"check","fill":""}}):_vm._e()],1)})},proxy:true}])})],1),_c('scatter-chart',{staticClass:"chart",attrs:{"chartData":{ datasets: _vm.timeXMarketData },"chartOptions":{
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: 'Años en la empresa',
              font: {
                size: 12,
                style: 'normal',
              },
              padding: { top: 10, left: 0, right: 0, bottom: 0 },
            },
          },
          y: {
            display: true,
            title: {
              display: true,
              text: 'xxxxx',
              font: {
                size: 12,
                style: 'normal',
              },
              padding: { top: 0, left: 0, right: 0, bottom: 10 },
            },
            ticks: {
              steps: 4,
              stepSize: 1,
              color: '#9096a5',
              font: {
                size: 11,
                weight: 500,
                family: 'Inter',
              },
              callback: function (value, index, ticks) {
                return ['Mínimo', 'Mediana', 'Media', 'Superior'][value];
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
        },
      }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }