<template>
  <div class="dashboard__container">
    <div>
      <div class="chart__title">
        <h4>Mercados x percentil?</h4>
        <Menu size="medium" direction="left" :closeOnItemClick="false">
          <template #label>
            <filter-item
              :filter="{
                id: 'positionsId',
                name: 'Posiciones',
                options: positions,
              }"
            />
          </template>
          <template #options>
            <menu-item
              v-for="position in positions"
              @click="
                marketSelectedPosition = position.id;
                positions.map((pos) => (pos.active = false));
                position.active = true;
              "
              :key="position.id"
            >
              {{ position.name }}
              <unicon
                v-if="marketSelectedPosition === position.id"
                width="16px"
                height="16px"
                name="check"
                fill=""
              ></unicon>
            </menu-item>
          </template>
        </Menu>
      </div>
      <bar-chart
        :chart-data="marketsData"
        :chart-options="{
          indexAxis: 'x',
          max: 1,
          steps: 5,
          stepSize: 0.2,
          plugins: {
            tooltip: {
              callbacks: {
                label: (ctx) => {
                  return [`Valor: ${Math.round(ctx.parsed.y * selectedPositionEmployees.length)}`];
                },
                title: ([context]) => `${marketsData.datasets[context.datasetIndex].label}`,
              },
            },
          },
        }"
      />
    </div>
    <div>
      <div class="chart__title">
        <h4>Años en la empresa x percentil?</h4>
        <Menu size="medium" direction="left" :closeOnItemClick="false">
          <template #label>
            <filter-item
              :filter="{
                id: 'marketsId',
                name: 'Mercados',
                options: markets,
              }"
            />
          </template>
          <template #options>
            <menu-item
              v-for="market in markets"
              @click="
                selectedMarket = market.id;
                markets.map((mark) => (mark.active = false));
                market.active = true;
              "
              :key="market.id"
            >
              {{ market.name }}
              <unicon
                v-if="selectedMarket === market.id"
                width="16px"
                height="16px"
                name="check"
                fill=""
              ></unicon>
            </menu-item>
          </template>
        </Menu>
      </div>
      <scatter-chart
        class="chart"
        :chartData="{ datasets: timeXMarketData }"
        :chartOptions="{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: 'Años en la empresa',
                font: {
                  size: 12,
                  style: 'normal',
                },
                padding: { top: 10, left: 0, right: 0, bottom: 0 },
              },
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'xxxxx',
                font: {
                  size: 12,
                  style: 'normal',
                },
                padding: { top: 0, left: 0, right: 0, bottom: 10 },
              },
              ticks: {
                steps: 4,
                stepSize: 1,
                color: '#9096a5',
                font: {
                  size: 11,
                  weight: 500,
                  family: 'Inter',
                },
                callback: function (value, index, ticks) {
                  return ['Mínimo', 'Mediana', 'Media', 'Superior'][value];
                },
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              display: false,
            },
          },
        }"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mean, median } from 'mathjs';
import BarChart from '@/components/charts/BarChart.vue';
import ScatterChart from '@/components/charts/ScatterChart.vue';
import Menu from '@/components/menu/Menu.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import FilterItem from '@/components/filters/FilterItem.vue';
import { timeStampToAge } from '@/dateFormats';

export default {
  components: {
    BarChart,
    ScatterChart,
    Menu,
    MenuItem,
    FilterItem,
  },
  props: {
    employees: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      mean,
      median,
      timeStampToAge,
      marketSelectedPosition: '',
      selectedMarket: '',
    };
  },
  mounted() {
    this.selectedMarket = this.markets[0].id;
    this.marketSelectedPosition = this.positions[0].id;
    this.markets[0].active = true;
    this.positions[0].active = true;
  },
  methods: {
    getKeyByValue(object, value) {
      let foundKey = null;
      const valPos = {
        min: 3,
        mean: 1,
        median: 2,
        max: 0,
      };
      Object.keys(object).forEach((key) => {
        if (object[key] === value) foundKey = key;
      });
      return valPos[foundKey];
    },
    getClosestValueIndex(market, salary, positionId) {
      if (!market) return null;
      const values = market.positions[positionId];
      if (!values) return null;
      return this.getKeyByValue(
        values,
        Object.values(values).reduce((closest, currentNumber) => {
          const compare = Math.abs(currentNumber - salary) < Math.abs(closest - salary);
          return compare ? currentNumber : closest;
        }, Infinity),
      );
    },
    getClosestValue(market, salary, positionId) {
      const values = market.positions[positionId];
      if (!values) return null;
      let foundKey = null;
      Object.keys(values).forEach((key) => {
        const val = Object.values(values).reduce((closest, currentNumber) => {
          const compare = Math.abs(currentNumber - salary) < Math.abs(closest - salary);
          return compare ? currentNumber : closest;
        }, Infinity);
        if (values[key] === val) foundKey = key;
      });
      return foundKey;
    },
  },
  computed: {
    ...mapState({
      company: (state) => state.company,
      categories: (state) => state.categories.categories,
      options: (state) => state.options.options,
      markets: (state) => state.markets.markets,
    }),
    ...mapState('jobEvaluation', {
      positions: (state) => state.positions,
    }),

    selectedPositionEmployees() {
      return this.employees.filter(({ puesto }) => puesto === this.marketSelectedPosition);
    },
    marketsData() {
      const datasets = [[], [], [], []];
      this.markets.forEach((market, i) => {
        for (let j = 0; j <= 3; j += 1) datasets[j][i] = 0;

        this.selectedPositionEmployees.forEach((employee) => {
          const closestValue = this.getClosestValueIndex(
            market,
            employee.salary,
            this.marketSelectedPosition,
          );
          if (closestValue !== null) datasets[closestValue][i] += 1;
        });
      });
      const chartData = { datasets: [], labels: this.markets.map(({ name }) => name) };
      ['Superior', 'Media', 'Mediana', 'Mínimo'].forEach((label, index) => {
        chartData.datasets.push({
          backgroundColor: [...this.company.colors, ...this.company.colors][index],
          data: datasets[index].map(
            (val, i) => val / datasets.reduce((sum, currentValue) => sum + currentValue[i], 0),
          ),
          label,
        });
      });
      return chartData;
    },

    timeXMarketData() {
      const data = [];
      this.employees.forEach(({ salary, entryDate, puesto }) => {
        data.push({
          y: [3, 2, 1, 0][
            this.getClosestValueIndex(
              this.markets.find(({ id }) => this.selectedMarket === id),
              salary,
              puesto,
            )
          ],
          x: this.timeStampToAge(entryDate),
        });
      });
      return [
        {
          label: 'Empleados',
          fill: false,
          borderColor: this.company.colors[0],
          backgroundColor: this.company.colors[0],
          data,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard__container {
  overflow: auto;
  gap: 1rem;
  display: grid;
  grid-template-areas:
    's1 s1 s1 s1'
    's2 s2 s2 s2';
  grid-template-rows: 25rem 25rem;
  grid-template-columns:
    calc(25% - 1rem)
    calc(25% - 1rem)
    calc(25% - 1rem)
    calc(25% - 1rem);

  & > div {
    height: 100%;
    display: flex;
    flex-flow: column;
    gap: 1rem;
    border: solid 1px var(--gray-color-500);
    border-radius: 3px;
    padding: 1rem;
    & > *:nth-child(2) {
      flex-grow: 1;
    }
    &:nth-child(1) {
      grid-area: s1;
    }
    &:nth-child(2) {
      grid-area: s2;
    }
  }

  .chart__title {
    display: flex;
    justify-content: space-between;
  }
}
</style>
