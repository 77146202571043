<template>
  <Modal ref="modal__config" size="sm">
    <template #title>Nuevo mercado</template>
    <template #content>
      <validation-observer tag="div" v-slot="{ invalid }" class="form__validation">
        <form class="form__content-market">
          <div>
            <custom-input v-model="marketName" label="Nombre" type="text" rules="required" />
            <div>
              <h5>Custom Color</h5>
              <div class="colors">
                <span
                  @click="selectColor(color)"
                  v-for="color in colors"
                  :key="color.value"
                  :class="{ isSelected: color.isSelected }"
                  :style="{ 'border-color': color.value }"
                >
                  <span :style="{ 'background-color': color.value }">
                    <unicon name="check" fill="currentColor" height="16px" width="16px" />
                  </span>
                </span>
              </div>
            </div>
            <div>
              <h5>Posiciones</h5>
              <file-input
                :isLoading="isUploadingFile"
                @uploadFile="(file) => parseFile(file)"
                :data="`${filePositions ? Object.keys(filePositions).length : 0} posicion(es)`"
                @removeFile="filePositions = null"
              />
            </div>
          </div>
        </form>
        <div class="modal__buttons">
          <Button @click="onSave" type="submit" :disabled="invalid" size="medium" variant="primary">
            {{ isLoading ? 'Guardando...' : 'Guardar' }}
          </Button>
        </div>
      </validation-observer>
    </template>
  </Modal>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import Papa from 'papaparse';

import Button from '@/components/buttons/Button.vue';
import Modal from '@/components/Modal.vue';
import FileInput from '@/components/custom/FileInput.vue';
import CustomInput from '@/components/custom/CustomInput.vue';

export default {
  components: {
    ValidationObserver,
    Modal,
    Button,
    FileInput,
    CustomInput,
  },
  data() {
    return {
      isLoading: false,
      isUploadingFile: false,
      filePositions: null,
      marketName: '',
      colors: [
        { value: '#FFB8BF', isSelected: false },
        { value: '#A8D7FE', isSelected: false },
        { value: '#FAD8B4', isSelected: false },
        { value: '#C8EABC', isSelected: false },
        { value: '#FEDE87', isSelected: false },
        { value: '#FFC6EE', isSelected: false },
        { value: '#BCB4B5', isSelected: false },
      ],
    };
  },
  methods: {
    ...mapMutations(['setAlert']),
    ...mapActions(['updateCompany']),
    ...mapActions('markets', ['addMarket', 'updateMarket', 'updateMarketsBatch']),
    ...mapActions('jobEvaluation', ['getPositions']),

    closeModal() {
      this.$refs.modal__config.close();
    },

    open() {
      this.$refs.modal__config.open();
    },

    async onSave() {
      this.isLoading = true;
      try {
        if (this.filePositions) {
          const color = this.colors.find(({ isSelected }) => isSelected);
          const newMarket = {
            name: this.marketName,
            isHidden: false,
            positions: this.filePositions,
            color: color ? color.value : '#1A96FC',
          };
          await this.addMarket(newMarket);
        }
        this.closeModal();
        this.setAlert({
          state: 'success',
          message: 'Mercado agregado',
        });
      } catch (e) {
        console.log(e);
        this.setAlert({
          state: 'error',
          message: 'Ocurrió un error, por favor inténtelo nuevamente',
        });
      }
      this.isLoading = false;
    },

    parseFile(file) {
      if (!file) return;
      this.isUploadingFile = true;

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        transformHeader: (header) => {
          switch (header.toLowerCase()) {
            case 'id':
              return 'id';
            case 'nombre':
              return 'name';
            case 'minimo':
              return 'min';
            case 'superior':
              return 'max';
            case 'media':
              return 'mean';
            case 'mediana':
              return 'median';
            default:
              return header.trim();
          }
        },
        transform: (value) => value.trim(),

        complete: (results) => {
          if (!results) return;
          this.filePositions = {};
          results.data.forEach(({ id, name, ...measures }) => {
            this.filePositions[id] = {
              ...Object.keys(measures).reduce(
                (acc, current) => ({
                  ...acc,
                  [current]: parseInt(measures[current], 0),
                }),
                {},
              ),
            };
          });
          this.isUploadingFile = false;
        },
      });
    },

    selectColor(color) {
      let clr = this.colors.find(({ isSelected }) => isSelected);
      if (clr) clr.isSelected = false;
      clr = this.colors.find(({ value }) => value === color.value);
      clr.isSelected = true;
    },
  },

  computed: {
    ...mapState({
      company: (state) => state.company,
    }),
    ...mapState('jobEvaluation', {
      positions: (state) => state.positions,
    }),
  },

  async mounted() {
    try {
      this.isLoading = true;
      await this.getPositions();
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss">
.employee__tabs {
  flex-grow: 1;
}
.form__content-market {
  & > div {
    padding: 1.5rem;
    gap: 1rem;
    display: flex;
    flex-flow: column;
    & > * {
      width: 100%;
    }
    & > div {
      display: flex;
      flex-flow: column;
      gap: 0.7rem;
    }
  }
  .colors {
    display: flex;
    gap: 0.375rem;
    & > span {
      height: 2rem;
      width: 2rem;
      display: grid;
      place-items: center;
      border-radius: 100%;
      border: none;
      transition: transform 0.25s;
      span {
        height: 80%;
        width: 80%;
        border-radius: 100%;
        display: grid;
        place-items: center;
        cursor: pointer;
        background-color: white;
      }
      &:hover {
        transform: scale(1.2);
      }
      .unicon {
        opacity: 0;
        color: white;
      }
      &.isSelected {
        border: solid 2px;
        .unicon {
          opacity: 100;
        }
      }
    }
  }
}
</style>
