var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"view__container"},[_c('div',{staticClass:"content__top"},[_c('div',[_c('Breadcrumbs',{attrs:{"views":[],"currentView":{ label: 'Estructura Salarial Externa', icon: 'globe' }}}),_c('h2',[_vm._v("Estructura Salarial Externa")])],1),_c('div',{staticClass:"content__buttons"},[_c('Sidemenu',{ref:"markets__menu",attrs:{"title":'Mercados',"actionButton":{ label: 'Nuevo mercado', action: 'addMarket' },"items":_vm.markets,"actionButtons":[
          {
            icon: 'eye-slash',
            icon2: 'eye',
            name: 'Ocultar',
            name2: 'Mostrar',
            isToggle: true,
            toggleProperty: 'isHidden',
          },
          { icon: 'trash', name: 'Eliminar', isDanger: true } ]},on:{"Ocultar":_vm.toggleHideMarket,"Eliminar":_vm.onDeleteMarket,"addMarket":_vm.openConfigModal,"editItems":_vm.editMarkets},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('Button',{attrs:{"type":"button","variant":"secondary","size":"small"}},[_vm._v(" Mercados ")])]},proxy:true}])}),_c('Menu',{attrs:{"direction":"left"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('Button',{attrs:{"type":"button","variant":"secondary","size":"xsmall"}},[_c('unicon',{staticClass:"ellipsis",attrs:{"name":"ellipsis-h","fill":"currentColor","height":"16px","width":"16px"}})],1)]},proxy:true},{key:"options",fn:function(){return [_c('menu-item',{on:{"click":_vm.downloadExcel}},[_c('unicon',{attrs:{"width":"16px","height":"16px","name":"file-download","fill":"var(--font-color-700)"}}),_vm._v(" Descargar Excel ")],1),_c('menu-item',{on:{"click":_vm.downloadPPT}},[_c('unicon',{attrs:{"width":"16px","height":"16px","name":"file-download","fill":"var(--font-color-700)"}}),_vm._v(" Descargar PPT ")],1)]},proxy:true}])})],1)]),_c('div',{staticClass:"filter__container"},[_c('Menu',{attrs:{"direction":"below","closeOnItemClick":false},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('filter-item',{attrs:{"filter":_vm.incomeFilter}})]},proxy:true},{key:"options",fn:function(){return _vm._l(([].concat( _vm.incomeFilter.options )),function(option){return _c('menu-item',{key:option.id,on:{"click":function($event){option.active = !option.active;
            _vm.filterEmployees();}}},[_vm._v(" "+_vm._s(option.name)+" "),(option.active)?_c('unicon',{attrs:{"width":"16px","height":"16px","name":"check","fill":""}}):_vm._e()],1)})},proxy:true}])}),_c('Filters',{ref:"filters",attrs:{"filters":_vm.categories,"filtersOptions":_vm.options},on:{"filter":function (activeFilters) {
          _vm.filters = activeFilters;
          _vm.filterEmployees(activeFilters);
        }}})],1),_c('div',{staticClass:"content"},[(!_vm.isLoading)?_c('Tabs',{attrs:{"tabList":[
        { title: 'Puestos', icon: 'table' },
        { title: 'Dashboard', icon: 'apps' } ]},scopedSlots:_vm._u([{key:"tabPanel-1",fn:function(){return [_c('div',{staticClass:"content"},[_c('table',{ref:"table"},[_c('tr',[_c('th',{attrs:{"rowspan":"2"}}),_c('th',{staticClass:"number",attrs:{"rowspan":"2"}},[_vm._v("#")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Puesto")]),_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Mediana")]),_vm._l((_vm.markets.filter(function (ref) {
            var isHidden = ref.isHidden;

            return !isHidden;
      })),function(market){return _c('th',{key:market.id,staticClass:"center",style:({ 'background-color': market.color, color: 'white' }),attrs:{"colspan":Object.values(market.positions[Object.keys(market.positions)[0]]).length}},[_vm._v(" "+_vm._s(market.name)+" ")])})],2),(_vm.markets.length && _vm.positions.length && !_vm.isLoading)?[_c('tr',{staticClass:"second__header"},[_vm._l((_vm.markets.filter(function (ref) {
            var isHidden = ref.isHidden;

            return !isHidden;
      })),function(market){return [_c('th',{key:market.id + 'header-min',style:({ 'background-color': market.color, color: 'white' })},[_vm._v(" Mínimo ")]),_c('th',{key:market.id + 'header-mean',style:({ 'background-color': market.color, color: 'white' })},[_vm._v(" Media ")]),_c('th',{key:market.id + 'header-median',style:({ 'background-color': market.color, color: 'white' })},[_vm._v(" Mediana ")]),_c('th',{key:market.id + 'header-max',style:({ 'background-color': market.color, color: 'white' })},[_vm._v(" Superior ")])]})],2),_vm._l((_vm.positions),function(position,index){return _c('tr',{key:position.id},[_c('td',[_c('Menu',{attrs:{"direction":index === _vm.positions.length && _vm.positions.length > 3 ? 'above' : 'below'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('unicon',{staticClass:"ellipsis",attrs:{"name":"ellipsis-h","fill":"currentColor","height":"16.5px","width":"16.5px"}})]},proxy:true},{key:"options",fn:function(){return [_c('menu-item',{on:{"click":function($event){return _vm.redirectToEmployees(position.id)}}},[_c('unicon',{attrs:{"width":"16px","height":"16px","name":"eye","fill":"var(--font-color-700)"}}),_vm._v(" Ver empleados ")],1)]},proxy:true}],null,true)})],1),_c('td',{staticClass:"number"},[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(position.name))]),(_vm.meanAndMedians.medians[position.id])?_c('td',[_c('span',[_vm._v(" "+_vm._s(position.payEquity && position.payEquity.median && position.payEquity.median.isActive ? position.payEquity.median.value.toFixed(2) : _vm.meanAndMedians.medians[position.id].toFixed(2))+" ")])]):_c('td',[_c('span',[_vm._v("0.00")])]),_vm._l((_vm.markets.filter(function (ref) {
            var isHidden = ref.isHidden;

            return !isHidden;
      })),function(market){return _vm._l((['min', 'mean', 'median', 'max']),function(val){return _c('td',{key:market.id + val},[_c('span',{staticClass:"market__value",class:{
                        selected:
                          market.positions[position.id] &&
                          _vm.getClosestValue(market, position) &&
                          _vm.getClosestValue(market, position) ===
                            market.positions[position.id][val],
                      },style:({
                        '--market-color': market.color,
                      })},[_vm._v(" "+_vm._s(market.positions[position.id] ? market.positions[position.id][val] : '-')+" ")])])})})],2)})]:_vm._e()],2),(!_vm.isLoading && !_vm.markets.length)?_c('empty-state',{attrs:{"label":"mercados","haveAction":false}}):_vm._e()],1),(_vm.isLoading)?_c('div',{staticClass:"loading"},[_c('loading-spinner')],1):_vm._e()]},proxy:true},{key:"tabPanel-2",fn:function(){return [_c('div',{staticClass:"content"},[_c('Dashboard',{ref:"dashboard",attrs:{"employees":_vm.filteredEmployees}})],1)]},proxy:true}],null,false,3879688255)}):_vm._e()],1),_c('NewMarket',{ref:"modal__config",on:{"update":_vm.filterEmployees}}),_c('confirm-dialogue',{ref:"confirmDialogue"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }