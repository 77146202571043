<template>
  <div class="view__container">
    <div class="content__top">
      <div>
        <Breadcrumbs
          :views="[]"
          :currentView="{ label: 'Estructura Salarial Externa', icon: 'globe' }"
        />
        <h2>Estructura Salarial Externa</h2>
      </div>
      <div class="content__buttons">
        <Sidemenu
          ref="markets__menu"
          :title="'Mercados'"
          :actionButton="{ label: 'Nuevo mercado', action: 'addMarket' }"
          :items="markets"
          :actionButtons="[
            {
              icon: 'eye-slash',
              icon2: 'eye',
              name: 'Ocultar',
              name2: 'Mostrar',
              isToggle: true,
              toggleProperty: 'isHidden',
            },
            { icon: 'trash', name: 'Eliminar', isDanger: true },
          ]"
          @Ocultar="toggleHideMarket"
          @Eliminar="onDeleteMarket"
          @addMarket="openConfigModal"
          @editItems="editMarkets"
        >
          <template #label>
            <Button type="button" variant="secondary" size="small"> Mercados </Button>
          </template>
        </Sidemenu>
        <!-- { icon: 'pen', name: 'Editar' },
          @editItems="'';"
          @Editar="openConfigModal"
          -->
        <Menu direction="left">
          <template #label>
            <Button type="button" variant="secondary" size="xsmall">
              <unicon
                class="ellipsis"
                name="ellipsis-h"
                fill="currentColor"
                height="16px"
                width="16px"
              />
            </Button>
          </template>
          <template #options>
            <menu-item @click="downloadExcel">
              <unicon
                width="16px"
                height="16px"
                name="file-download"
                fill="var(--font-color-700)"
              ></unicon>
              Descargar Excel
            </menu-item>
            <menu-item @click="downloadPPT">
              <unicon
                width="16px"
                height="16px"
                name="file-download"
                fill="var(--font-color-700)"
              ></unicon>
              Descargar PPT
            </menu-item>
          </template>
        </Menu>
      </div>
    </div>
    <div class="filter__container">
      <Menu direction="below" :closeOnItemClick="false">
        <template #label>
          <filter-item :filter="incomeFilter" />
        </template>
        <template #options>
          <menu-item
            v-for="option in [...incomeFilter.options]"
            @click="
              option.active = !option.active;
              filterEmployees();
            "
            :key="option.id"
          >
            {{ option.name }}
            <unicon v-if="option.active" width="16px" height="16px" name="check" fill=""></unicon>
          </menu-item>
        </template>
      </Menu>
      <Filters
        @filter="
          (activeFilters) => {
            filters = activeFilters;
            filterEmployees(activeFilters);
          }
        "
        :filters="categories"
        :filtersOptions="options"
        ref="filters"
      />
    </div>
    <div class="content">
      <Tabs
        v-if="!isLoading"
        :tabList="[
          { title: 'Puestos', icon: 'table' },
          { title: 'Dashboard', icon: 'apps' },
        ]"
      >
        <template v-slot:tabPanel-1>
          <div class="content">
            <table ref="table">
              <tr>
                <th rowspan="2"></th>
                <th rowspan="2" class="number">#</th>
                <th rowspan="2">Puesto</th>
                <th rowspan="2">Mediana</th>
                <th
                  class="center"
                  :colspan="
                    Object.values(market.positions[Object.keys(market.positions)[0]]).length
                  "
                  v-for="market in markets.filter(({ isHidden }) => !isHidden)"
                  :key="market.id"
                  :style="{ 'background-color': market.color, color: 'white' }"
                >
                  {{ market.name }}
                </th>
              </tr>
              <template v-if="markets.length && positions.length && !isLoading">
                <tr class="second__header">
                  <template v-for="market in markets.filter(({ isHidden }) => !isHidden)">
                    <th
                      :style="{ 'background-color': market.color, color: 'white' }"
                      :key="market.id + 'header-min'"
                    >
                      Mínimo
                    </th>
                    <th
                      :style="{ 'background-color': market.color, color: 'white' }"
                      :key="market.id + 'header-mean'"
                    >
                      Media
                    </th>
                    <th
                      :style="{ 'background-color': market.color, color: 'white' }"
                      :key="market.id + 'header-median'"
                    >
                      Mediana
                    </th>
                    <th
                      :style="{ 'background-color': market.color, color: 'white' }"
                      :key="market.id + 'header-max'"
                    >
                      Superior
                    </th>
                  </template>
                </tr>
                <tr v-for="(position, index) in positions" :key="position.id">
                  <td>
                    <Menu
                      :direction="
                        index === positions.length && positions.length > 3 ? 'above' : 'below'
                      "
                    >
                      <template #label>
                        <unicon
                          class="ellipsis"
                          name="ellipsis-h"
                          fill="currentColor"
                          height="16.5px"
                          width="16.5px"
                        />
                      </template>
                      <template #options>
                        <menu-item @click="redirectToEmployees(position.id)">
                          <unicon
                            width="16px"
                            height="16px"
                            name="eye"
                            fill="var(--font-color-700)"
                          ></unicon>
                          Ver empleados
                        </menu-item>
                      </template>
                    </Menu>
                  </td>
                  <td class="number">{{ index + 1 }}</td>
                  <td>{{ position.name }}</td>
                  <td v-if="meanAndMedians.medians[position.id]">
                    <span>
                      {{
                        position.payEquity &&
                        position.payEquity.median &&
                        position.payEquity.median.isActive
                          ? position.payEquity.median.value.toFixed(2)
                          : meanAndMedians.medians[position.id].toFixed(2)
                      }}
                    </span>
                  </td>
                  <td v-else><span>0.00</span></td>
                  <template v-for="market in markets.filter(({ isHidden }) => !isHidden)">
                    <td v-for="val in ['min', 'mean', 'median', 'max']" :key="market.id + val">
                      <span
                        class="market__value"
                        :class="{
                          selected:
                            market.positions[position.id] &&
                            getClosestValue(market, position) &&
                            getClosestValue(market, position) ===
                              market.positions[position.id][val],
                        }"
                        :style="{
                          '--market-color': market.color,
                        }"
                      >
                        {{
                          market.positions[position.id] ? market.positions[position.id][val] : '-'
                        }}
                      </span>
                    </td>
                  </template>
                </tr>
              </template>
            </table>
            <empty-state
              v-if="!isLoading && !markets.length"
              label="mercados"
              :haveAction="false"
            />
          </div>
          <div v-if="isLoading" class="loading"><loading-spinner /></div>
        </template>
        <template v-slot:tabPanel-2>
          <div class="content">
            <Dashboard :employees="filteredEmployees" ref="dashboard" />
          </div>
        </template>
      </Tabs>
    </div>

    <NewMarket ref="modal__config" @update="filterEmployees" />
    <confirm-dialogue ref="confirmDialogue" />
  </div>
</template>

<script>
/* eslint-disable */
import { mapMutations, mapActions, mapState } from 'vuex';

import { mean, median } from 'mathjs';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import Filters from '@/components/filters/Filters.vue';
import Menu from '@/components/menu/Menu.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import FilterItem from '@/components/filters/FilterItem.vue';
import Button from '@/components/buttons/Button.vue';
import Sidemenu from '@/components/menu/Sidemenu.vue';
import ConfirmDialogue from '@/components/ConfirmDialogue.vue';
import LoadingSpinner from '@/components/loading/LoadingSpinner.vue';
import EmptyState from '@/components/EmptyState.vue';
import Tabs from '@/components/Tabs.vue';
import pptxgen from 'pptxgenjs';
import * as XLSX from 'xlsx';
import * as XLSXS from 'xlsx-style';
import NewMarket from './modals/NewMarket.vue';
import Dashboard from './Dashboard.vue';

export default {
  components: {
    Breadcrumbs,
    Menu,
    MenuItem,
    FilterItem,
    Filters,
    Button,
    NewMarket,
    Sidemenu,
    ConfirmDialogue,
    LoadingSpinner,
    Tabs,
    Dashboard,
    EmptyState,
  },
  data() {
    return {
      mean,
      median,
      isLoading: false,
      filteredEmployees: [],
      filters: [],
      incomeFilter: {
        id: 'incomeId',
        name: 'Ingresos',
        options: [
          {
            id: 'miniumWage',
            name: 'Sueldo base',
            active: true,
            index: 0,
          },
          {
            id: 'otherIncomes',
            name: 'Otros ingresos',
            active: false,
            index: 1,
          },
        ],
      },
    };
  },
  computed: {
    ...mapState('jobEvaluation', {
      positions: (state) => state.positions,
    }),
    ...mapState({
      employees: (state) => state.employees.employees,
      categories: (state) => state.categories.categories,
      options: (state) => state.options.options,
      markets: (state) => state.markets.markets,
    }),
    ...mapState({
      company: (state) => state.company,
    }),
    meanAndMedians() {
      const medians = {};
      const means = {};
      const salariesByPosition = {};
      this.filteredEmployees.forEach((employee) => {
        if (!salariesByPosition[employee.puesto]) salariesByPosition[employee.puesto] = [];
        salariesByPosition[employee.puesto].push(employee.salary);
      });
      Object.keys(salariesByPosition).forEach((position) => {
        medians[position] = this.median(salariesByPosition[position]);
        means[position] = this.mean(salariesByPosition[position]);
      });
      return { medians, means };
    },
  },

  methods: {
    ...mapActions('jobEvaluation', ['getPositions']),
    ...mapActions('employees', ['fetchEmployees']),
    ...mapActions('categories', ['fetchCategories']),
    ...mapActions('options', ['fetchOptions']),
    ...mapActions('markets', [
      'fetchMarkets',
      'deleteMarket',
      'updateMarket',
      'updateMarketsBatch',
    ]),
    ...mapMutations(['setAlert']),
    ...mapActions(['updateCompany']),

    getClosestValue(market, position) {
      const values = market.positions[position.id];
      if (!values) return 0;
      const equityValueExist = position.payEquity && position.payEquity.median;
      const useEquityValue = equityValueExist && position.payEquity.median.isActive;
      return Object.values(values).reduce((closest, currentNumber) => {
        const eqValue = useEquityValue
          ? position.payEquity.median.value
          : this.meanAndMedians.medians[position.id];
        return Math.abs(currentNumber - eqValue) < Math.abs(closest - eqValue)
          ? currentNumber
          : closest;
      }, Infinity);
    },
    async editMarkets(marketsToEdit) {
      await this.updateMarketsBatch(marketsToEdit);
    },
    async toggleHideMarket(selectedMarket) {
      const market = this.markets.find((e) => e.id === selectedMarket.id);
      await this.updateMarket({ ...selectedMarket, isHidden: !market.isHidden });
      this.$refs.markets__menu.setCopyItems();
    },
    async onDeleteMarket(market) {
      const ok = await this.$refs.confirmDialogue.show({
        title: 'Eliminar mercado',
        message:
          '¿Estas seguro que quieres eliminar este mercado? Eliminar un mercado lo removera permanentemente, esta acción es irreversible.',
        isDestructive: true,
        okButtonText: 'Si, eliminar',
      });

      if (!ok) return;

      try {
        await this.deleteMarket(market.id);

        this.setAlert({
          state: 'success',
          message: 'Mercado eliminado',
        });
      } catch (error) {
        console.log(error);
        this.setAlert({
          state: 'error',
          message: 'Ocurrió un error, por favor inténtelo nuevamente',
        });
      } finally {
        this.$refs.markets__menu.selectedItem = {};
      }
    },

    downloadPPT() {
      /* eslint-disable */
      const pptx = new pptxgen();
      const config = {
        x: 1,
        // y: 1.2,
        y: 0.31,
        w: 8,
        // h: 4.3,
        h: 5,
        catAxisLabelColor: '000000',
        catAxisLabelFontSize: 8,
        valAxisLabelFontSize: 8,
        dataLabelFontSize: 8,
        legendFontSize: 8,
        titleFontSize: 10,
        chartColors: this.company.colors,
        dataLabelPosition: 't',
        dataLabelFormatCode: '#%',
        valAxisLabelFormatCode: '#%',
        showValue: true,
        showTitle: true,
        showLegend: true,
        legendPos: 't',
        valAxisMaxVal: 1,
      };

      const slide = pptx.addSlide();

      slide.addText('Estructura Salarial Externa', {
        x: 0,
        y: 2.61,
        h: 0.4,
        w: '100%',
        align: 'center',
        fontSize: 32,
      });

      let text = '';
      this.$refs.filters.activeFilters.forEach((filter, i) => {
        let options = filter.options.filter((option) => option.active);
        text += options.length ? ' / ' + filter.name + ': ' : '';
        options.forEach((option, index) => {
          text += option.name + (index == options.length - 1 ? '' : ', ');
        });
        text += text.length && i === this.$refs.filters.activeFilters.length - 1 ? ' /' : '';
      });
      slide.addText(text, {
        x: 0,
        y: 3.1,
        h: 0.4,
        w: '100%',
        align: 'center',
        fontSize: 14,
      });

      let newSlide = pptx.addSlide();
      newSlide.addChart(
        pptx.ChartType.scatter,
        [
          {
            name: 'Años en la empresa',
            values: this.$refs.dashboard.timeXMarketData[0].data.map(({ x }) => x),
          },
          {
            name: 'xxxxx',
            values: this.$refs.dashboard.timeXMarketData[0].data.map(({ y }) => y),
          },
        ],
        {
          title: 'Años en la empresa X xxxxx',
          showTitle: true,
          titleFontSize: 10,
          valAxisTitle: 'xxxxx',
          showValAxisTitle: true,
          lineSize: 0,
          catAxisTitle: 'Años en la empresa',
          showCatAxisTitle: true,
          showLabel: true, // Must be set to true or labels will not be shown
          dataLabelPosition: 'b', // Options: 't'|'b'|'l'|'r'|'ctr'
          x: 1,
          y: 0.31,
          w: 8,
          h: 5,
          chartColors: this.company.colors,
        },
      );
      newSlide = pptx.addSlide();
      newSlide.addChart(
        pptx.ChartType.bar,
        this.$refs.dashboard.marketsData.datasets.map((dataset) => ({
          name: dataset.label,
          labels: this.$refs.dashboard.marketsData.labels,
          values: dataset.data,
        })),
        {
          ...config,
          title: 'Mercados x xxxxx',
        },
      );

      pptx.writeFile({ fileName: 'dashboard.pptx' });
    },
    saveBlobAsFile(name, blob) {
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    },

    s2ab(s) {
      let buf = new ArrayBuffer(s.length);
      let view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    },
    downloadExcel() {
      const wb = XLSX.utils.book_new();
      const $table = this.$refs.table;
      const sheet = XLSX.utils.table_to_sheet($table);
      const workbook = {
        SheetNames: ['puestos'],
        Sheets: { puestos: sheet },
      };

      workbook.SheetNames.forEach((sheetName) => {
        const ws = workbook.Sheets[sheetName];
        const range = XLSX.utils.decode_range(ws['!ref']);

        for (let C = range.s.c; C <= range.e.c; ++C) {
          const address = XLSX.utils.encode_cell({ r: 0, c: C });
          if (!ws[address]) continue;
          ws[address].s = { fill: { fgColor: { rgb: '1A96FC' } } };
        }
      });

      const workbookout = XLSXS.write(workbook, {
        bookType: 'xlsx',
        type: 'binary',
      });

      this.saveBlobAsFile(
        `estructura_salarial_externa-puestos.xlsx`,
        new Blob([this.s2ab(workbookout)], { type: 'application/octet-stream' }),
      );
    },
    redirectToEmployees(positionId) {
      this.$router.push({
        name: 'EmployeesByPosition',
        params: {
          positionId,
        },
      });
    },
    openConfigModal() {
      this.$refs.modal__config.open();
    },

    filterEmployees(filters = []) {
      const activeFilters = [];
      const employees = JSON.parse(JSON.stringify(this.employees));
      filters.forEach((filter) => {
        const activeOptions = filter.options
          .filter((option) => option.active)
          .map((option) => option.id);

        if (activeOptions.length) {
          activeFilters.push({
            id: filter.id,
            type: filter.type,
            options: activeOptions,
          });
        }
      });

      this.filteredEmployees = employees.filter((employee) => {
        for (let index = 0; index < activeFilters.length; index += 1) {
          const filter = activeFilters[index];
          if (!filter.options.includes(employee[filter.id])) {
            return false;
          }
        }
        return true;
      });
      this.filteredEmployees.forEach((employee, index) => {
        const mult = this.company.salaryEquityCongif.multiplier || 1;
        const salary = mult * this.getTotalIncome(employee.miniumWage, employee.otherIncomes);
        this.filteredEmployees[index].salary = salary;
      });
    },

    getTotalIncome(miniumWage, otherIncomes) {
      const accOtherIncomes = otherIncomes.reduce((acc, val) => acc + val, 0);
      const activeIncomes = this.incomeFilter.options
        .filter(({ active }) => active)
        .map(({ id }) => id);
      if (activeIncomes.length === 0) return 0;
      if (activeIncomes.length === 2) return accOtherIncomes + miniumWage;
      if (activeIncomes[0] === 'miniumWage') return miniumWage;
      return accOtherIncomes;
    },
  },

  async mounted() {
    try {
      this.isLoading = true;
      if (!this.positions.length) await this.getPositions();
      if (!this.employees.length) await this.fetchEmployees();
      if (!this.categories.length) await this.fetchCategories();
      if (!this.options.length) await this.fetchOptions();
      if (!this.markets.length) await this.fetchMarkets();
      this.filterEmployees();
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.view__container {
  border-radius: 10px;
  background-color: white;
  height: 100%;

  .content__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1rem;
  }

  .content__buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
  }
}
.filter__container {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
  align-items: center;
  flex-wrap: wrap;
}
.content {
  width: 100%;
  overflow: auto;
  background-color: white;
  height: 100%;
}
table {
  width: 100%;

  .table-action {
    cursor: pointer;
    color: var(--main-color-500);
    &:hover {
      text-decoration: underline var(--main-color-500);
    }
  }
}

.stats-button {
  color: var(--main-color-500);
  transition: color 300ms;

  &:hover {
    color: var(--main-color-600);
  }
}

.market__value {
  padding: 0.375rem;
  border-radius: 3px;
  &.selected {
    font-size: 0.75rem;
    font-weight: var(--medium);
    width: 4rem;
    text-align: center;
    background-color: var(--market-color);
  }
}

table {
  th:first-child,
  td:first-child {
    padding: 0.5rem;
    width: 2.03125rem;
    min-width: 2.03125rem;
    max-width: 2.03125rem;
  }
}

.median {
  font-size: 0.75rem;
  background-color: var(--main-color-200);
  padding: 0.375rem;
  border-radius: 3px;
  width: 4.5rem;
  text-align: center;
  font-weight: var(--medium);
}
</style>
